import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { compose, prop } from "ramda"
import { getMarkdownEdges, getQueryData, getSectionDataFor } from "@utils/utils"

/**
 * import Sections
 */
import MainLayout from "@layouts/MainLayout/MainLayout"
import SEO from "@components/shared/SEO/SEO"

/**
 * quering data
 */

export const query = graphql`
  query DataprivacyQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/data-privacy" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            subtitle
            title
            section
          }
          internal {
            content
          }
          html
        }
      }
    }
  }
`

const Dataprivacy = ({ data }) => {
  const content = compose(getQueryData, getMarkdownEdges)(data)
  const dataPrivacySectionData = getSectionDataFor("data-privacy", content)

  const dataPrivacyDetailsHtml = {
    __html: prop("html", dataPrivacySectionData),
  }

  return (
    <MainLayout>
      <SEO />
      <section className="data-privacy d-flex bg-light">
        <Container className="my-2 py-5">
          <Row className="my-5 d-flex justify-content-center small">
            <Col
              xs={11}
              sm={12}
              lg={10}
              xl={9}
              dangerouslySetInnerHTML={dataPrivacyDetailsHtml}
            ></Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  )
}

export default Dataprivacy
